
// Vue
import {computed, defineComponent, ref} from "vue";

// Components
import PortadaInicio from "@/components/PortadaInicio.vue";
import PorQueUtilizar from "@/components/PorQueUtilizar.vue";
import InformacionKit from "@/components/InformacionKit.vue";
import ImagenReferencia from "@/components/ImagenReferencia.vue";
import ImagenAeropuerto from "@/components/ImagenAeropuerto.vue";
import SobreBestTracking from "@/components/SobreBestTracking.vue";
import ResumenInformacion from "@/components/ResumenInformacion.vue";
import FormularioContacto from "@/components/FormularioContacto.vue";
import ListaCaracteristicas from "@/components/ListaCaracteristicas.vue";
import vuetify from "@/plugins/vuetify";

export default defineComponent({
  name: "VistaInicio",
  components: {
    PortadaInicio,
    InformacionKit,
    PorQueUtilizar,
    ImagenAeropuerto,
    ImagenReferencia,
    SobreBestTracking,
    FormularioContacto,
    ResumenInformacion,
    ListaCaracteristicas,
  },
  setup() {
    // Data

    const scroll = ref(0);

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    // Methods

    const onScroll = () => {
      scroll.value = document.documentElement.scrollTop;
    };

    return {
      // Data
      scroll,
      // Computed
      mobile,
      // Methods
      onScroll,
    };
  },
});
