
// Vue
import {defineComponent} from "vue";

// Components
import AppBar from "@/components/portadaInicio/AppBar.vue";
import FormularioCotizar from "@/components/portadaInicio/FormularioCotizar.vue";

export default defineComponent({
  name: "PortadaInicio",
  props: {
    scroll: {
      type: Number,
      required: true,
    },
    mobile: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AppBar,
    FormularioCotizar,
  },
});
