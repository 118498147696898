
// Vue
import {defineComponent, ref} from "vue";

export default defineComponent({
  name: "FormularioContacto",
  setup() {
    // Data

    const asuntos = [
      "Consultas",
    ];

    const nombre = ref("");
    const telefono = ref("");
    const email = ref("");
    const asunto = ref("");
    const mensaje = ref("");

    return {
      // Data
      email,
      nombre,
      asunto,
      asuntos,
      mensaje,
      telefono,
    };
  },
});
