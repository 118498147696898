
// Vue
import {defineComponent} from "vue";

export default defineComponent({
  name: "PorQueUtilizar",
  setup() {
    // Data

    const razones = [{
      icon: "timer",
      text: "Permite optimizar el tiempo, planificar viajes, controlar el patio y posicionamiento de vehículos",
    }, {
      icon: "distance",
      text: "Impacta positivamente en toda la cadena de suministros con integración de sistemas de logística.",
    }, {
      icon: "street-map",
      text: "Accede de manera inmediata a la información de andenes, turnos y viajes en cualquier periodo.",
    }, {
      icon: "guarantee",
      text: "Registros de viajes en tiempo real de los distintos procesos logísticos, entreplantas y despacho",
    }, {
      icon: "price-up",
      text: "Disminuye costos operativos, notifica rendimiento de trabajo y aumenta la calidad de servicio.",
    }, {
      icon: "contract",
      text: "Desarrolla métricas de rendimiento para indicar la mejora progresiva del trabajo en andenes.",
    }];

    return {
      // Data
      razones,
    };
  },
});
