
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

export default defineComponent({
  name: "AppBar",
  props: {
    scroll: {
      type: Number,
      required: true,
    },
    mobile: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    // Data

    const botones = [{
      id: "#inicio",
      text: "INICIO",
    }, {
      id: "#sobre-nosotros",
      text: "SOBRE NOSOTROS",
    }, {
      id: "#soluciones",
      text: "SOLUCIONES",
    }, {
      id: "#contacto",
      text: "CONTACTO",
    }];

    // Computed

    const appBarColor = computed(() => props.scroll > 114 ? "#000000" : "transparent");

    const appBarHeight = computed(() => props.mobile ? '200px' : '122px');

    // Methods

    const goTo = (id: string) => {
      vuetify.framework.goTo(id);
    };

    return {
      // Data
      botones,
      // Computed
      appBarColor,
      appBarHeight,
      // Methods
      goTo,
    };
  },
});
