
// Vue
import {defineComponent} from "vue";

export default defineComponent({
  name: "ListaCaracteristicas",
  setup() {
    // Data

    const caracteristicas = [{
      titulo: "Ubicación",
      texto: "Best Tracking monitorea las ramplas acopladas, identifica aglomeraciones y notifica atrasos de llegada al andén.",
    }, {
      titulo: "Aplicaciones",
      texto: "Accede a nuestra web a través de distintos perfiles como supervisor de tráfico, planificador de viajes, analistas de datos y vista aeropuerto.",
    }, {
      titulo: "Planificación",
      texto: "Supervisa tu planificación diaria de distribución logística para despacho y recepción de productos en el complejo industrial.",
    }, {
      titulo: "Análisis",
      texto: "Conoce y mejora el rendimiento actual de la operación, identificando los factores que retrasan el despacho de productos.",
    }];

    return {
      // Data
      caracteristicas,
    };
  },
});
